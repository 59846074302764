<template>
    <table class="show_header">
        <thead>
            <tr>
                <th></th>
                <th>Contacts</th>
                <th>Pages</th>
                <th>Action</th>
                <th>Timezone</th>
                <th>Schedule</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody v-if="schedules.total">
            <tr v-for="(schedule, s) in schedules.data" :key="s">
                <td>
                    <label :for="`check-${s}`" class="checkbox">
                        <input type="checkbox" :id="`check-${s}`" :value="schedule.id" v-model="scheduleIds" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                </td>
                <td>
                    <button class="success" v-for="(contact, c) in schedule.contacts" :key="c">{{ contact.name }}</button>
                </td>
                <td>
                    <button class="success" v-for="(page, p) in schedule.pages" :key="p">{{ page.name }}</button>
                </td>
                <td>
                    {{ schedule.action == '/contacts/send-page' ? 'Send Page' : 'Restrict Page' }}
                </td>
                <td>{{ schedule.time_zone }}</td>
                <td>{{ (moment.utc(schedule.schedule_utc).tz(schedule.time_zone).format("ddd MMM DD YYYY | hh:mm a")) }}</td>
                <td>
                    <button class="success" v-if="schedule.in_queue == 1 && schedule.expired_time_in_minutes < 15">Sending</button>
                    <button class="failed" v-else-if="schedule.in_queue == 2 || (schedule.in_queue == 1 && schedule.expired_time_in_minutes > 15)">Failed</button>
                    <button v-else>Waiting</button>
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td colspan="4" class="px-4">No Records Found</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import moment from 'moment-timezone'

    export default {
        name: 'Scheduled Sms',

        data () {
            return {
                scheduleIds: [],
                moment
            }
        },

        props: {
            schedules: Object,
            modelValue: [Object, Array],
            updateSelectAll: {
                type: Function,
                default: () => {},
            },
        },

        emit: ['update:modelValue'],

        watch:{
            modelValue: {
                handler (ids) {
                    const vm  = this;
                    vm.scheduleIds = ids;
                },
                deep: true,
            },

            scheduleIds (ids) {
                const vm  = this;

                if (Object.keys(vm.schedules.data).length !== ids.length) {
                    vm.updateSelectAll(0);
                } else {
                    vm.updateSelectAll(1);
                }

                vm.$emit('update:modelValue', ids);
            }
        },
    }
</script>

<style scoped>
    table button{
        padding: 4px 5px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
        margin: 4px;
        display: flex;
    }

    table button.success{
        background: #00aa14;
    }

    table button.failed{
        background-color: #ED2417
    }

    table button.failed{
        background-color: #ED2417
    }
</style>
